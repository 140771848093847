// extracted by mini-css-extract-plugin
export var alignLeft = "M_rd d_fp d_bG d_dv";
export var alignCenter = "M_bP d_fq d_bD d_dw";
export var alignRight = "M_rf d_fr d_bH d_dx";
export var verticalSolidComponent = "M_wn";
export var compContent = "M_vX d_r";
export var normal = "M_wp d_Z d_cb";
export var reverse = "M_wq d_Z d_bK";
export var last = "M_vY";
export var empty = "M_wr d_r";
export var empty2 = "M_ws d_bw";
export var borderRight = "M_wt d_b8 d_c1";
export var borderLeft = "M_wv d_b9 d_c1";