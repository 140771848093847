// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "P_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "P_fR d_fR d_bz d_bJ";
export var menuDesign6 = "P_wx d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "P_wy d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "P_wz d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "P_wB d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "P_wC d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "P_qP d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "P_qZ d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "P_wD";
export var navbarItem = "P_qQ d_bx";
export var navbarLogoItemWrapper = "P_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "P_wF d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "P_wG d_gd d_by d_Z d_bs";
export var burgerToggle = "P_wH d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "P_wJ d_gd d_by d_Z d_bs";
export var burgerInput = "P_wK d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "P_wL d_f3 d_w d_H";
export var burgerLine = "P_wM d_f1";
export var burgerMenuDesign6 = "P_wN d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "P_wP d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "P_wQ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "P_wR d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "P_wS d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "P_wT d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "P_wV d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "P_wW d_bC d_bP";
export var compact = "P_wX";
export var navDivided = "P_wY";
export var staticBurger = "P_wZ";
export var menu = "P_w0";
export var navbarDividedLogo = "P_w1";
export var nav = "P_w2";