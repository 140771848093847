// extracted by mini-css-extract-plugin
export var alignLeft = "J_rd d_fp d_bG d_dv";
export var alignCenter = "J_bP d_fq d_bD d_dw";
export var alignRight = "J_rf d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "J_vT";
export var wrap = "J_vV d_bJ d_cb";
export var topPadding = "J_vW d_cX";
export var compContent = "J_vX d_w";
export var last = "J_vY";
export var box = "J_vZ d_cr";
export var boxWrapper = "J_v0 d_w d_bz";
export var boxWrapperFirst = "J_v1 d_w d_bz";