// extracted by mini-css-extract-plugin
export var articleWrapper = "r_m4";
export var searchResult = "r_rS r_m4";
export var line = "r_fk d_fk d_cY";
export var articleTitle = "r_rT d_cq";
export var articleText = "r_m5 d_cs";
export var authorImage = "r_ln d_ln d_b7 d_Z d_ct";
export var authorText = "r_nw d_ch d_ct";
export var textCenter = "r_dw d_dw";
export var searchWrapper = "r_rV d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "r_rW d_cF d_w d_b4";
export var searchBtn = "r_rX d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "r_nH d_cr";
export var elementWrapper = "r_my d_cl";
export var select = "r_rY d_w d_b4";
export var articleImageWrapper = "r_nm d_cs d_Z d_V";
export var articleImageWrapperIcon = "r_nn d_cs d_Z d_V";
export var articleImageWrapperColumns = "r_rZ d_cs d_fh d_Z";
export var contentPadding = "r_r0 d_cS";
export var otherPadding = "r_r1 d_cM";
export var paginationWrapper = "r_r2";
export var pagination = "r_r3";
export var disabledPagination = "r_r4";
export var breakMe = "r_r5";
export var activePage = "r_r6";
export var next = "r_r7";
export var pages = "r_r8";
export var searchTitle = "r_r9 d_cr";
export var categoryTitle = "r_sb d_cr d_Z";
export var searchText = "r_sc d_ct";
export var bold = "r_sd";
export var field = "r_sf";
export var dateTag = "r_nd d_l d_cr d_dq";
export var icon1 = "r_nf d_cP";
export var icon2 = "r_ng d_cP d_cJ";
export var tagBtn = "r_nh d_b1 d_cD d_dp d_dB";
export var linksWrapper = "r_nz d_ch d_dw";
export var header = "r_m6 d_w d_Z";
export var headerInner = "r_sg";
export var headerImage = "r_sh d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "r_nv d_cY";
export var resultImage = "r_sj";