// extracted by mini-css-extract-plugin
export var lbContainerOuter = "m_qt";
export var lbContainerInner = "m_qv";
export var movingForwards = "m_qw";
export var movingForwardsOther = "m_qx";
export var movingBackwards = "m_qy";
export var movingBackwardsOther = "m_qz";
export var lbImage = "m_qB";
export var lbOtherImage = "m_qC";
export var prevButton = "m_qD";
export var nextButton = "m_qF";
export var closing = "m_qG";
export var appear = "m_qH";