// extracted by mini-css-extract-plugin
export var noPadding = "L_fj d_fj";
export var videoFrameWrapper = "L_wh d_kS d_b5";
export var videoIframeStyle = "L_nl d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "L_wj d_kR d_bK";
export var videoInnerWrapper = "L_wk d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "L_wl d_dw d_w";
export var videoInnerInnerWrapper = "L_wm d_bz d_bD d_bJ";
export var btnWrapper = "L_d2 d_w d_bz d_bD d_cj";
export var btn = "L_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n H_vv H_vd";
export var icon = "L_nF d_cC";
export var textLeft = "L_dv";
export var textCenter = "L_dw";
export var textRight = "L_dx";