// extracted by mini-css-extract-plugin
export var alignLeft = "B_rd d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_rf d_fr d_bH d_dx";
export var leftWrapper = "B_tq d_bz d_bD d_bJ";
export var wrapper = "B_rp d_w d_cc";
export var imgWrapper = "B_tr";
export var caption = "B_ts";
export var text = "B_nH d_cs";
export var instagramGuttersImage = "B_g2 d_g2 d_cv d_K d_cv";
export var instagramNoGuttersImage = "B_g1 d_g1 d_cD d_K d_cD";
export var instagramContainerFull = "B_tt d_dT d_w";
export var instagramContainer = "B_tv d_dW";
export var content = "B_tw d_cc";
export var errorContainer = "B_tx";
export var instagramImg = "B_ty d_w d_H d_dY d_bd d_0 d_8 d_7 d_4 d_9 d_bR";
export var instagramImageWrapper = "B_tz d_fg d_Z";
export var instagramBtnWrapper = "B_g9 d_g9 d_0 d_bC d_bP d_bX d_dw d_bq";
export var instagramBtnWrapperLeft = "B_tB d_d2 d_d1 d_w d_bz d_bG d_c7";
export var instagramBtnWrapperCenter = "B_tC d_d3 d_d1 d_w d_bz d_bD d_c7";
export var instagramBtnWrapperRight = "B_tD d_d4 d_d1 d_w d_bz d_bH d_c7";