// extracted by mini-css-extract-plugin
export var articleWrapper = "j_m4";
export var articleText = "j_m5 d_dv d_cs";
export var header = "j_m6 d_Z d_w";
export var headerImageWrapper = "j_m7 d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "j_m8 d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "j_m9 d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "j_nb d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "j_nc d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "j_mv d_w";
export var dateTag = "j_nd d_cf d_cr d_dq d_w";
export var icon1 = "j_nf d_cP";
export var icon2 = "j_ng d_cP d_cJ";
export var tagBtn = "j_nh d_b1 d_cD d_dp d_dB";
export var headerText = "j_nj d_w d_cf d_cs d_bW";
export var center = "j_nk d_dw";
export var videoIframeStyle = "j_nl d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "j_nm d_cs d_Z";
export var articleImageWrapperIcon = "j_nn d_cs d_Z";
export var articleRow = "j_np d_bD";
export var quoteWrapper = "j_gQ d_bC d_bP d_cs";
export var quoteBar = "j_nq d_H";
export var quoteText = "j_nr";
export var authorBox = "j_ns d_w";
export var authorRow = "j_nt d_bD d_bW d_cy";
export var separator = "j_nv d_w";
export var line = "j_fk d_w d_fk d_cY d_cy";
export var authorImage = "j_ln d_b7 d_Z d_w d_ct";
export var authorText = "j_nw d_ch d_ct";
export var masonryImageWrapper = "j_nx";
export var bottomSeparator = "j_ny d_w d_cy";
export var linksWrapper = "j_nz d_ch d_dv";
export var comments = "j_nB d_bD d_cy";
export var sharing = "j_nC d_bz d_bD d_bJ";
export var shareText = "j_nD d_w d_dw";
export var icon = "j_nF";
export var customRow = "j_nG d_bD d_Z";
export var text = "j_nH";
export var SubtitleSmall = "j_nJ";
export var SubtitleNormal = "j_nK";
export var SubtitleLarge = "j_nL";