// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_s6 d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_s7 d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_s8 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_s9 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_tb d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_tc d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_td d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_tf H_tf";
export var heroExceptionNormal = "z_tg H_tg";
export var heroExceptionLarge = "z_th H_th";
export var Title1Small = "z_tj H_tj H_tL H_tM";
export var Title1Normal = "z_tk H_tk H_tL H_tN";
export var Title1Large = "z_tl H_tl H_tL H_tP";
export var BodySmall = "z_tm H_tm H_tL H_t3";
export var BodyNormal = "z_tn H_tn H_tL H_t4";
export var BodyLarge = "z_tp H_tp H_tL H_t5";