import React from 'react';
import { Link } from 'gatsby';
import HTMLParser from 'html-react-parser';
import { format, isValid } from 'date-fns/esm';
import clsx from 'clsx';

import SectionMedia from '../../LayoutComponents/SectionMedia';
import { formColor } from '../../../../../helper';
import CustomizableButton from '../../../../CustomizableButton';
import getCategoryIds from '../../../../../helpers/getCategoryIds';
import findCategoryById from '../../../../../helpers/findCategoryById';

import * as styles from './styles.module.css';

class ArticleLoopLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const dataIndex = props.section.data.findIndex((elem) => elem.type === 'DATA');

    let startIndex;
    let loopLength;
    let showExcerpt;
    let showThumbnails;
    let showLink;
    let onlyFeatured;
    let limitTags;
    let linkedTo;
    let categoryId;
    let sortOrder;
    let showDate;

    if (dataIndex > -1) {
      ({
        startIndex,
        loopLength,
        showExcerpt,
        showThumbnails,
        showLink,
        onlyFeatured,
        limitTags,
        linkedTo,
        categoryId,
        sortOrder,
        showDate,
      } = props.section.data[dataIndex]);
    }

    const articles = [...(props?.articleMother?.articles || [])];
    if (categoryId && !Array.isArray(categoryId)) categoryId = [categoryId];
    else if (categoryId?.length === 0 || categoryId?.includes('')) categoryId = undefined;
    if (sortOrder === 'old') articles?.reverse();

    let layout = 'side';
    if (props.section && props.section.styles) ({ layout } = props.section.styles);

    const linkBtnStyles = props.buttons.find((btn) => btn.id === props.section.styles.linkBtnId);

    const categoryIds = [];
    if (categoryId && props.articleMother) {
      const { categories } = props.articleMother.styles;
      categoryId.forEach((c) => {
        const category = findCategoryById(categories, c);
        if (category) {
          categoryIds.push(...getCategoryIds(category));
        }
      });
    }

    this.state = {
      linkBtnStyles,
      showExcerpt,
      showThumbnails,
      showLink,
      onlyFeatured,
      startIndex,
      loopLength,
      layout,
      linkedTo,
      limitTags,
      categoryId,
      categoryIds,
      articles,
      showDate,
    };
  }

  createLoopImage = (articleLink, image, index, imageStyle, imgWrapper, mediaWrapper) => {
    let sizes =
      '(min-width: 1200px) 1140px, (min-width: 992px) 960px, (min-width: 768px) 720px, (min-width: 576px) 540px, 100vw';
    if (this.state.layout === 'below') {
      sizes = '(min-width: 768px) 33vw, (min-width: 576px) 540px, 100vw';
    }

    const img = (
      <div key={`article_${this.props.section._id}_link_${index}`} className={styles[imgWrapper]}>
        {this.state.showThumbnails && image.id && (
          <Link to={articleLink} draggable="false">
            <SectionMedia
              mediaType="IMAGE"
              wrapperStyleName={mediaWrapper}
              imageStyleName={imageStyle}
              id={image.id}
              alt={image.alt || ''}
              data={image}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              categoryPathList={this.props.categoryPathList}
              sectionSlugs={this.props.sectionSlugs}
              sizes={sizes}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
            {this.state.layout === 'hero' && <div className={styles.heroOverlay} />}
          </Link>
        )}
      </div>
    );

    return img;
  };

  createLoopElement = (index, featured, pubDate, title, excerpt, image, link, color3, color0, amount) => {
    const validDate = isValid(new Date(pubDate));
    const displayDate = validDate ? format(new Date(pubDate), 'd.M.yyyy') : pubDate;
    const { layout, showExcerpt, showLink, showThumbnails, linkBtnStyles } = this.state;
    const { section, themeData } = this.props;
    let loopElement;

    let col;
    let outerWrapper;
    let imgWrapper;
    let frameWrapper;
    let loopWrapper;
    let infoWrapper;
    let linkWrapper;
    let textWrapper;
    let titleColor = color3;
    let imageStyle = 'articleLoopImage';
    let mediaWrapper = 'imageContent2';
    let color;
    let lnk;
    let shadow = {};
    let textColor;
    if (
      section.styles.linkColor &&
      section.styles.linkColor.color &&
      (section.styles.linkType === 'text' || !section.styles.linkType)
    ) {
      textColor = {
        color: formColor(
          { solid: section.styles.linkColor.color },
          false,
          section.styles.linkColor.opacity,
          undefined,
          themeData.colors,
        ).backgroundColor,
      };
    }

    const to = `${link.target}`;

    if (layout === 'side') {
      if (section.styles.columns) col = `col-12 col-md-${12 / section.styles.columns}`;
      else col = `col-12${amount >= 2 ? ' col-lg-6' : ''}${amount >= 3 ? ' col-xl-4' : ''}`;
      imgWrapper = featured === true ? 'imageWrapperFeatured' : 'imageWrapper1';
      frameWrapper = 'frameWrapper1';
      loopWrapper = featured === true ? 'articleLoopWrapperFeaturedLayout1' : 'articleLoopWrapper1';
      infoWrapper = 'infoWrapper1';
      textWrapper = 'textWrapper1';
      outerWrapper = 'articleLoopOuterWrapper1';
      color = { color: `${featured === true ? 'white' : 'black'}` };
      titleColor = featured === true ? { color: 'white' } : color3;

      let linkContent = section.styles.linkText || link.text;
      if (section.styles.linkType === 'button' && linkBtnStyles) linkContent = this.createButton(link.text);

      lnk = (
        <div>
          <Link to={to} style={textColor || color} draggable="false">
            {linkContent}
          </Link>
        </div>
      );
    } else if (layout === 'below' || layout === 'hero') {
      col = `col-12${section.styles.columns ? ` col-md-${12 / section.styles.columns}` : ''}`;
      textWrapper = 'textWrapper2';
      let border;
      let bg;
      let linkColor;

      if (layout === 'below') {
        imgWrapper = featured === true ? 'imageWrapperFeatured' : 'imageWrapper2';
        frameWrapper = 'frameWrapper2';
        loopWrapper = featured === true ? 'articleLoopWrapperFeatured' : 'articleLoopWrapper2';
        infoWrapper = featured === true ? 'infoWrapperFeatured' : 'infoWrapper2';
        border = featured === true ? 'none' : `2px solid ${color0}`;
        bg = featured === true ? color0 : 'transparent';
        color = { color: `${featured === true ? 'white' : 'black'}` };
        titleColor = featured === true ? { color: 'white' } : color3;
        imageStyle = featured === true ? 'articleLoopImage' : 'imageContent';
        mediaWrapper = featured === true ? 'imageContent2' : 'imageContent';
        outerWrapper = 'articleLoopOuterWrapper2';
        linkColor = textColor || color;
      } else {
        frameWrapper = 'frameWrapper3';
        loopWrapper = 'articleLoopWrapper3';
        infoWrapper = 'infoWrapper1';
        mediaWrapper = 'imageContent';
        border = 'none';
        bg = '#fff';
        outerWrapper = 'articleLoopOuterWrapper3';
        color = { color: '#fff' };
        titleColor = { color: '#fff' };
        linkColor = textColor || { color: '#000' };
        shadow = { textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' };
      }

      linkWrapper = {
        border,
        padding: '10px 14px',
        width: 'fit-content',
        backgroundColor: bg,
        cursor: 'pointer',
      };

      let wrapperStyle = { padding: '10px 0' };
      let linkContent = <span className="BUTTON" style={{ ...linkWrapper, ...linkColor }}>{section.styles.linkText || link.text}</span>;
      if (section.styles.linkType === 'button' && linkBtnStyles) linkContent = this.createButton(link.text);
      else if (section.styles.linkType === 'text') {
        wrapperStyle = undefined;
        linkContent = section.styles.linkText || link.text;
      }

      lnk = (
        <div style={wrapperStyle}>
          <Link to={to} draggable="false">
            {linkContent}
          </Link>
        </div>
      );
    }

    if (col !== undefined && col !== null) {
      loopElement = (
        <div key={`article_loop_${index}`} className={`${col}  ${styles[outerWrapper]}`}>
          <div className={styles[frameWrapper]}>
            {showThumbnails && image.id && this.createLoopImage(to, image, index, imageStyle, imgWrapper, mediaWrapper)}
            <div className={styles[loopWrapper]}>
              <div className={styles[infoWrapper]}>
                {this.state.showDate !== false && (
                  <div className={styles[textWrapper]}>
                    <span style={{ ...color, ...shadow }}>{displayDate}</span>
                  </div>
                )}
                <div className={styles[textWrapper]}>
                  <Link to={to} draggable="false">
                    <h3 style={{ ...titleColor, ...shadow }}>{HTMLParser(title)}</h3>
                  </Link>
                </div>
                {showExcerpt && (
                  <div className={styles[textWrapper]}>
                    <span className={styles.infoExcerpt} style={{ ...color, ...shadow }}>
                      {HTMLParser(excerpt)}
                    </span>
                  </div>
                )}
                {showLink && lnk}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return loopElement;
  };

  createButton = (text = '') => (
    <CustomizableButton
      data={{ styles: this.state.linkBtnStyles }}
      label={this.props.section.styles.linkText || text}
      themeData={this.props.themeData}
      Element="span"
      className={styles.loop}
    />
  );

  render() {
    const color3 = {
      color: this.props.themeData.colors[3],
    };

    const color0 = this.props.themeData.colors[0];

    const activeElements = [];
    if (this.props.articleMother && this.props.articleMother._id.toString() === this.state.linkedTo) {
      this.state.articles?.some((article, index) => {
        let tagLimit = true;
        if (this.state.limitTags && this.state.limitTags.active && this.state.limitTags.list.length > 0) {
          tagLimit = false;
          article.tags.some((tag) => {
            let found = false;
            if (this.state.limitTags.list?.includes(tag)) {
              found = true;
              tagLimit = true;
            }

            return found;
          });
        }

        if (
          (this.state.onlyFeatured === true ? article.featured === true : true) &&
          tagLimit &&
          (!this.state.categoryId || (article.categoryId && this.state.categoryIds?.includes(article.categoryId)))
        ) {
          if (index >= Number(this.state.startIndex) && activeElements.length < Number(this.state.loopLength))
            activeElements.push(article);
        }

        return activeElements.length === Number(this.state.loopLength);
      });
    }

    const align = `align${this.props.section.styles.align || 'Center'}`;
    return (
      <div className={styles.wrapper}>
        {this.props.section.data.map((elem, i) => {
          let result = null;
          if (elem.type === 'HEADINGS/HEADING-TWO' && elem.active) {
            result = (
              <div
                key={`${this.props.section._id}_Title_${i}_section`}
                className={`container ${styles.articleLoopMainHeader} ${
                  styles[`align${elem.align || this.props.section.styles.align || 'Center'}`]
                }`}
              >
                {HTMLParser(`<h2>${elem.text}</h2>`)}
              </div>
            );
          } else if (elem.type === 'HEADINGS/HEADING-FOUR' && elem.active) {
            result = (
              <div
                key={`${this.props.section._id}_Title_${i}_section`}
                className={`container ${styles.articleLoopSubHeader} ${
                  styles[`align${elem.align || this.props.section.styles.align || 'Center'}`]
                }`}
              >
                {HTMLParser(`<h3 style="font-weight: inherit; font-family: inherit">${elem.text}</h3>`)}
              </div>
            );
          }

          return result;
        })}
        <div
          className={this.props.section.styles.full && this.props.layout === 'hero' ? 'container-fluid' : 'container'}
        >
          <div className={clsx('row', this.state.layout !== 'hero' && 'row-eq-height', styles[align])}>
            {activeElements.map((article, index) => {
              return this.createLoopElement(
                index,
                article.featured,
                article.pubDate,
                article.title,
                article.excerpt,
                article.image,
                article.link,
                color3,
                color0,
                activeElements.length,
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleLoopLayout;
